import React from 'react';
import styled from 'styled-components';

type PropsType = {};

const Footer = ({}: PropsType) => (
  <>
    <Divider />
    <Container>
      <Contacts>
        <ul>
          <li>
            <h2>Auxilium Solutions</h2>
            <p>
              43010, м. Луцьк, <br />
              вул. Коперника 36а, оф.2
            </p>
          </li>
          <li>
            <h2>Auxilium Solutions</h2>
            <p>
              01054, м.Київ, <br />
              ВУЛИЦЯ ЯРОСЛАВІВ ВАЛ, <br />
              будинок 13/2, ЛІТЕРА "Б"
            </p>
          </li>
        </ul>
      </Contacts>
      <Copyright>
        {/* <ul>media buttons here</ul> */}
        <small>
          {`Copyright © ${new Date().getFullYear()} Auxilium Solutions. All rights
        reserved`}
        </small>
      </Copyright>
    </Container>
  </>
);

export default Footer;

const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  color: var(--black);
`;

const Contacts = styled.address`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }

  li {
    font-size: 1.4rem;
    line-height: 1.7rem;

    &:not(:last-child) {
      margin-right: 50px;
    }
  }

  h2 {
    font-size: 1.4rem;
    font-style: normal;
    line-height: 1.7rem;
    font-weight: 700;
    margin: 0 0 10px;
  }

  p {
    margin: 0;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 1.7rem;
  }
`;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ul {
    margin-bottom: 20px;
  }

  small {
    margin-top: auto;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: right;
  }
`;

const Divider = styled.hr`
  border: none;
  border-bottom: 1px solid #d6d9db;
  margin: 40px 30px;
`;
