import styled from 'styled-components';
import Formol, { Field } from 'formol';

export const Form = styled(Formol)`
  background-color: #fff;
  z-index: 1;
  padding: 3rem 4rem;
  border-radius: 1rem;
  max-width: 62.4rem;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormField = styled(Field)`
  width: 100%;

  label {
    font-size: 1.6rem;
  }

  input {
    width: 100%;
  }
`;
