import styled from 'styled-components';

export default styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1360px;

  /* @media screen and (min-width: $tablet-min) {
    padding: 2rem 0;
    max-width: 70.6rem;
  }

  @media screen and (min-width: $desktop) {
    max-width: 136rem;
  } */

  &:not(:last-child) {
    margin-bottom: 8rem;
  }
`;
