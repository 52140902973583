import styled from 'styled-components';

export default styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--black);
  margin: 0 0 4rem;
`;
