import React, { ReactNode } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Header, Footer } from '../../components';

import '../../assets/styles/normalize.css';
import '../../assets/styles/reset.css';
import '../../assets/styles/main.css';

type PropsType = {
  children: ReactNode;
};

const MainLayout = ({ children }: PropsType) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;
