import React from 'react';
import styled from 'styled-components';
import Image from '../image';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  max-height: 65px;
  box-shadow: 0px 2px 7px rgba(19, 30, 41, 0.1),
    0px 6px 4px rgba(19, 30, 41, 0.06);

  img {
    height: 45px;
  }
`;

const Navigation = styled.nav`
  a {
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
    transition: color 0.3s ease-in-out;

    &:hover {
      color: rgba(var(--black), 0.5);
    }

    &:not(:last-child) {
      margin-right: 4rem;
    }
  }
`;

type PropsType = {};

const Header = ({}: PropsType) => (
  <StyledHeader>
    <a href="/">
      <img src={require('../../assets/logo/horizontal.svg')} alt="home" />
    </a>
    <Navigation>
      <a href="tel:+38(095)301-68-61">{`(095) 301-68-61`}</a>
      {/* <a href="#services">{`Послуги`}</a>
      <a href="#contact-us">{`Зв'яжіться з нами`}</a> */}
    </Navigation>
  </StyledHeader>
);

export default Header;
