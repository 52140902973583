import React from 'react';

import LandingPageSection from '../LandingPageSection';
import SectionHeader from '../SectionHeader';
import Paragraph from '../Paragraph';

type PropsType = {};

const AboutUsSection = ({}: PropsType) => (
  <LandingPageSection>
    <SectionHeader>Про Auxilium Solutions</SectionHeader>
    <Paragraph>
      Компанія «Auxilium Solutions» створена в 2019 році шляхом об’єднання
      окремих компаній та спеціалістів, працюючих на ринку з 2005 року, в єдину
      групу.
    </Paragraph>
    <Paragraph>
      Ми пропонуємо широкий спектр консалтингових послуг, які відповідають
      різноманітним запитам наших міжнародних та українських клієнтів.
    </Paragraph>
  </LandingPageSection>
);

export default AboutUsSection;
