import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Image from '../image';
import LandingPageSection from '../LandingPageSection';

type PropsType = { imageFilename: string };

const HeroBannerSection = ({ imageFilename }: PropsType) => (
  <HeroBannerWrapper>
    <BackgroundImageWrapper>
      <Image filename={imageFilename} />
    </BackgroundImageWrapper>
    <TopBannerContent>
      <TopBannerTitle>Auxilium Solutions</TopBannerTitle>
      <TopBannerSubtitle>
        Покриття повного спектру глобальних фінансових послуг
      </TopBannerSubtitle>
    </TopBannerContent>
  </HeroBannerWrapper>
);

export default HeroBannerSection;

const TopBannerContent = styled.div`
  position: absolute;
  padding: 10rem 15rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
`;

const TopBannerTitle = styled.h1`
  font-size: 4.8rem;
  line-height: 5.9rem;
  margin: 0 0 1rem;
`;

const TopBannerSubtitle = styled.h2`
  font-size: 2.4rem;
  line-height: 2.9rem;
  margin: 0;
`;

const HeroBannerWrapper = styled(LandingPageSection)`
  max-width: 100%;
  height: 45vh;
  position: relative;
  overflow: hidden;
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
